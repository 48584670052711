<template>
  <div class="UnitInfo">
    <OrganizationMenu />
    <div class="wrap">
      <el-descriptions title="参建单位信息" border :column="2">
        <template slot="extra">
          <el-button
            type="primary"
            v-if="isRead"
            @click="toggleEditMode"
            size="small"
            plain
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            type="primary"
            v-if="!isRead"
            size="small"
            plain
            icon="el-icon-check"
            @click="saveEditData"
            >保存</el-button
          >
          <el-button
            type="danger"
            v-if="!isRead && action != 'add'"
            @click="isRead = true"
            size="small"
            plain
            icon="el-icon-close"
            >取消</el-button
          >
          <el-button
            type="danger"
            v-if="isRead || action =='add'"
            @click="$router.go(-1)"
            size="small"
            plain
            icon="el-icon-back"
            >返回</el-button
          >
        </template>
        <el-descriptions-item label="单位名称" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.name }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.name"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="单位简介" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.synopsis }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.synopsis"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="单位类型">
          <span v-if="isRead">{{ getUnitTypeName(readData.isDevop) }}</span>
          <el-select
            v-if="!isRead"
            size="small"
            v-model="editData.isDevop"
            placeholder="请选择单位类型"
            style="width: 100%;"
            clearable
          >
            <el-option
              v-for="item in unitTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="单位性质">
          <span v-if="isRead">{{ readData.natureOfCompany }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.natureOfCompany"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">
          <span v-if="isRead">{{ readData.socialCreditCode }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.socialCreditCode"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="营业执照号">
          <span v-if="isRead">{{ readData.businessNo }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.businessNo"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="组织机构代码">
          <span v-if="isRead">{{ readData.organizingCode }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.organizingCode"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="邮编">
          <span v-if="isRead">{{ readData.postcode }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.postcode"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="注册所在地">
          <span v-if="isRead">{{ readData.registration }}</span>
          <el-cascader
            v-if="!isRead"
            size="small"
            style="width: 100%;"
            v-model="editData.registrationData"
            :options="regionData"
            :props="{
              expandTrigger: 'click',
              checkStrictly: true,
              value: 'name',
              label: 'name',
            }"
            clearable
            @change="changeRegistrationData"
          >
          </el-cascader>
        </el-descriptions-item>
        <el-descriptions-item label="营业地址">
          <span v-if="isRead">{{ readData.address }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.address"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="法定代表人">
          <span v-if="isRead">{{ readData.legalEntity }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.legalEntity"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="法定代表人手机号">
          <span v-if="isRead">{{ readData.legalPhone }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.legalPhone"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="法定代表人证件号">
          <span v-if="isRead">{{ readData.certificateNumber }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.certificateNumber"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="注册资本">
          <span v-if="isRead">{{ readData.registeredCapital }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.registeredCapital"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="成立日期">
          <span v-if="isRead">{{ readData.establishTime }}</span>
          <el-date-picker
            v-if="!isRead"
            v-model="editData.establishTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item label="办公电话">
          <span v-if="isRead">{{ readData.officePhone }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.officePhone"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="传真号码">
          <span v-if="isRead">{{ readData.faxNo }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.faxNo"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="座机电话">
          <span v-if="isRead">{{ readData.landlinePhone }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.landlinePhone"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="联系人">
          <span v-if="isRead">{{ readData.unitLeader }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.unitLeader"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="联系人电话">
          <span v-if="isRead">{{ readData.phone }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.phone"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="邮箱地址">
          <span v-if="isRead">{{ readData.eAddress }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.eAddress"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="企业网址">
          <span v-if="isRead">{{ readData.corporateWebsiteUrl }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.corporateWebsiteUrl"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <span v-if="isRead">{{ readData.remark }}</span>
          <el-input
            v-if="!isRead"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            size="small"
            v-model="editData.remark"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
      
      <div class="wrap-blank"></div>

      <el-descriptions title="单位附件" border :column="1">
        <el-descriptions-item label="营业执照" labelClassName="my-label1" contentClassName="my-content1">
          <el-image
            v-if="isRead && readData.businessLicensePath"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.businessLicensePath"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadBusinessLicensePath'
            >
            <img v-if="blob1" :src="blob1">
            <i v-if="!blob1" class="el-icon-upload"></i>
            <div v-if="!blob1" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob1" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item label="资质证书" labelClassName="my-label1" contentClassName="my-content1">
          <div v-if="isRead && readData.enterprisePic && readData.enterprisePic.length">
            <el-image
              v-for="item in readData.enterprisePic"
              :key="item"
              style="width: 288px; height: 162px; display: block; margin-bottom: 10px;"
              :src="'api' + item"
              fit="scale-down">
            </el-image>
          </div>

          <div v-if="!isRead">
            <div v-for="item, index in editData.enterprisePic" :key="index" class="enterprisePicItem">
              <el-image
                style="width: 288px; height: 162px; display: block;"
                :src="'api' + item"
                fit="scale-down">
              </el-image>
              <i class="el-icon-delete enterprisePicDel" @click="editData.enterprisePic.splice(index, 1)"></i>
            </div>
          </div>

          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadEnterprisePic'
            >
            <img v-if="blob2" :src="blob2">
            <i v-if="!blob2" class="el-icon-upload"></i>
            <div v-if="!blob2" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob2" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
      </el-descriptions>      
    </div>
  </div>
</template>

<style lang="less" scope>
.UnitInfo {
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
  }
  .my-label1 {
    width: 20%;
  }
  .my-content1 {
    width: 80%;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .upload-demo {
    width: 288px;
    height: 162px;
  }
  .el-upload-dragger {
    width: 288px;
    height: 162px;
  }
}
.enterprisePicItem {
  width: 288px;
  height: 162px;
  margin-bottom: 10px;
  position: relative;
}
.enterprisePicDel {
  position: absolute;
  top: 71px;
  right: -20px;
  font-size: 20px;
}
</style>


<script>
import OrganizationMenu from "@/components/OrganizationMenu.vue";

export default {
  name: "UnitInfo",
  components: {
    OrganizationMenu,
  },
  data() {
    return {
      loading: null,
      regionData: [],
      unitTypeList: [],

      isRead: true,

      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },

      fileFormData: null, // 将要上传的formdata数据
      blob1: null,
      fileFormData2: null,
      blob2: null,

      readData: {
        name: null, // 单位名称
        synopsis: null, // 单位简介
        isDevop: null, // 单位类型
        natureOfCompany: null, // 单位性质
        socialCreditCode: null, // 统一社会信用代码
        businessNo: null, // 营业执照号
        organizingCode: null, // 组织机构代码
        postcode: null, // 邮编
        registration: null, // 注册所在地
        address: null, // 营业地址
        legalEntity: null, // 法定代表人
        legalPhone: null, // 法定代表人手机号	
        certificateNumber: null, // 法定代表人证件号
        registeredCapital: null, // 注册资本
        registeredCapitalData: null, // 注册资本(数组结果)
        establishTime: null, // 成立日期
        officePhone: null, // 办公电话
        faxNo: null, // 传真号码
        landlinePhone: null, // 座机电话
        unitLeader: null, // 联系人
        phone: null, // 联系人电话	
        eAddress: null, // 邮箱地址
        corporateWebsiteUrl: null, // 企业网址
        remark: null, // 备注
        businessLicensePath: null, // 营业执照
        enterprisePic: [], // 资质证书
      },
      editData: {
        name: null, // 单位名称
        synopsis: null, // 单位简介
        isDevop: null, // 单位类型
        natureOfCompany: null, // 单位性质
        socialCreditCode: null, // 统一社会信用代码
        businessNo: null, // 营业执照号
        organizingCode: null, // 组织机构代码
        postcode: null, // 邮编
        registration: null, // 注册所在地
        registrationData: null, // 注册所在地（数组结果）
        address: null, // 营业地址
        legalEntity: null, // 法定代表人
        legalPhone: null, // 法定代表人手机号	
        certificateNumber: null, // 法定代表人证件号
        registeredCapital: null, // 注册资本	
        establishTime: null, // 成立日期
        officePhone: null, // 办公电话
        faxNo: null, // 传真号码
        landlinePhone: null, // 座机电话
        unitLeader: null, // 联系人
        phone: null, // 联系人电话	
        eAddress: null, // 邮箱地址
        corporateWebsiteUrl: null, // 企业网址
        remark: null, // 备注
        businessLicensePath: null, // 营业执照
        enterprisePic: [], // 资质证书
      },
    };
  },
  computed: {
    action: function () {
      return this.$route.query.action;
    },
    projectId: function () {
      return this.$route.query.projectId;
    },
    deptId: function () {
      return this.$route.query.deptId;
    },
  },
  mounted() {
    this.getRegionData(); // 加载省市区数据
    this.getUnitTypeList(); // 加载单位类型数据
    this.getUnitInfo(); // 根据 id 获取单位信息
  },
  watch: {},
  methods: {
    // 加载省市区数据
    getRegionData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "/data/region.json",
        })
        .then((res) => {
          console.log("getRegionData =>", res);
          that.regionData = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位类型数据
    getUnitTypeList: function () {
      var that = this;
      that.radio = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "api/admin/dict/type/company_type",
        })
        .then((res) => {
          console.log(res);
          that.unitTypeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 根据 id 获取单位信息
    getUnitInfo: function () {
      var that = this;
      if (that.deptId == null & that.action == 'add') {
        that.isRead = false
        return;
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        deptId: that.deptId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/getById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getUnitInfo =>", res);
          that.readData = JSON.parse(JSON.stringify(res.data))
          if (res.data.enterprisePic) {
            that.readData.enterprisePic = JSON.parse(res.data.enterprisePic)
          } else {
            that.readData.enterprisePic = []
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 进入编辑模式
    toggleEditMode: function () {
      this.isRead = false
      this.editData = JSON.parse(JSON.stringify(this.readData))
      // this.editData.registration = this.editData.registration.split(' | ')
      this.blob1 = null
    },
    // 保存表单数据
    saveEditData: function () {
      var that = this
      if (!this.editData.name) {
        this.$message.error('单位名称不能为空');
        return
      }
      if (!this.editData.isDevop) {
        this.$message.error('单位类型不能为空');
        return
      }
      if (that.action == 'add') {
        that.onAddUnit() // 创建单位
      } else if (that.action == 'read') {
        that.onEditUnit() // 编辑单位
      }
    },
    onAddUnit: function () {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.projectId, // 所属项目 id
        name: that.editData.name, // 单位名称
        synopsis: that.editData.synopsis, // 单位简介
        isDevop: that.editData.isDevop, // 单位类型
        natureOfCompany: that.editData.natureOfCompany, // 单位性质
        socialCreditCode: that.editData.socialCreditCode, // 统一社会信用代码
        businessNo: that.editData.businessNo, // 营业执照号
        organizingCode: that.editData.organizingCode, // 组织机构代码
        postcode: that.editData.postcode, // 邮编
        registration: that.editData.registration, // 注册所在地
        address: that.editData.address, // 营业地址
        legalEntity: that.editData.legalEntity, // 法定代表人
        legalPhone: that.editData.legalPhone, // 法定代表人手机号	
        certificateNumber: that.editData.certificateNumber, // 法定代表人证件号
        registeredCapital: that.editData.registeredCapital, // 注册资本	
        establishTime: that.editData.establishTime, // 成立日期
        officePhone: that.editData.officePhone, // 办公电话
        faxNo: that.editData.faxNo, // 传真号码
        landlinePhone: that.editData.landlinePhone, // 座机电话
        unitLeader: that.editData.unitLeader, // 联系人
        phone: that.editData.phone, // 联系人电话	
        eAddress: that.editData.eAddress, // 邮箱地址
        corporateWebsiteUrl: that.editData.corporateWebsiteUrl, // 企业网址
        remark: that.editData.remark, // 备注
        businessLicensePath: that.editData.businessLicensePath, // 营业执照
        enterprisePic: JSON.stringify(that.editData.enterprisePic), // 资质证书
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/insert",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("onAddUnit =>", res);
          if (res.code == 0) {
            that.$notify({
              title: "操作成功",
              message: "已成功添加单位",
              type: "success",
            });
            that.$router.push({ name: 'Unit' })
          } else {
            that.$notify({
              title: "警告",
              message: "添加单位失败",
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    onEditUnit: function () {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        deptId: that.deptId, // 所属项目 id
        name: that.editData.name, // 单位名称
        synopsis: that.editData.synopsis, // 单位简介
        isDevop: that.editData.isDevop, // 单位类型
        natureOfCompany: that.editData.natureOfCompany, // 单位性质
        socialCreditCode: that.editData.socialCreditCode, // 统一社会信用代码
        businessNo: that.editData.businessNo, // 营业执照号
        organizingCode: that.editData.organizingCode, // 组织机构代码
        postcode: that.editData.postcode, // 邮编
        registration: that.editData.registration, // 注册所在地
        address: that.editData.address, // 营业地址
        legalEntity: that.editData.legalEntity, // 法定代表人
        legalPhone: that.editData.legalPhone, // 法定代表人手机号	
        certificateNumber: that.editData.certificateNumber, // 法定代表人证件号
        registeredCapital: that.editData.registeredCapital, // 注册资本	
        establishTime: that.editData.establishTime, // 成立日期
        officePhone: that.editData.officePhone, // 办公电话
        faxNo: that.editData.faxNo, // 传真号码
        landlinePhone: that.editData.landlinePhone, // 座机电话
        unitLeader: that.editData.unitLeader, // 联系人
        phone: that.editData.phone, // 联系人电话	
        eAddress: that.editData.eAddress, // 邮箱地址
        corporateWebsiteUrl: that.editData.corporateWebsiteUrl, // 企业网址
        remark: that.editData.remark, // 备注
        businessLicensePath: that.editData.businessLicensePath, // 营业执照
        enterprisePic: JSON.stringify(that.editData.enterprisePic), // 资质证书
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/edit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("onAddUnit =>", res);
          if (res.code == 0) {
            that.$notify({
              title: "操作成功",
              message: "已成功编辑单位",
              type: "success",
            });
            that.$router.push({ name: 'Unit' })
          } else {
            that.$notify({
              title: "警告",
              message: "编辑单位失败",
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传营业执照
    uploadBusinessLicensePath: function (file) {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.blob1 = URL.createObjectURL(file.file)
          that.editData.businessLicensePath = res.data.data.url
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传资质证书
    uploadEnterprisePic: function (file) {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          // that.blob1 = URL.createObjectURL(file.file)
          that.editData.enterprisePic.push(res.data.data.url)
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 所属地区发生变化
    changeRegistrationData: function (event) {
      console.log('changeRegistrationData =>', event);
      this.editData.registration = event.join(' | ')
      this.editData.address = event.join('')
    },
    // 根据单位类型 id 获取单位类型
    getUnitTypeName (data) {
      if (!data || !this.unitTypeList.length) {
        return null
      }
      var obj
      this.unitTypeList.forEach((item, index) => {
        if (item.id == data) {
          obj = item
        }
      })
      return obj.label
    }
  },
};
</script>